.mat-form-field-appearance-legacy .mat-form-field-underline,
.mat-slide-toggle-bar {
  background-color: var(--color-natural-40);
}

.mat-checkbox-frame,
.mat-radio-outer-circle {
  border-color: var(--color-natural-40);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--color-natural-40);
}

.mat-form-field {
  margin-bottom: var(--spacing-4x);

  &.ng-touched.ng-invalid {
    .mat-input-element:not(:focus) {
      border-color: var(--color-danger-100);
    }
  }

  &.mat-form-field-appearance-legacy .mat-form-field-label {
    color: var(--color-natural-100);
  }

  .mat-input-element {
    color: var(--color-natural-180);
    background: #FFFFFF;
    border-color: var(--color-natural-40);

    &:focus {
      border-color: var(--color-secondary-120);
    }

    &:hover {
      border-color: var(--color-secondary-80);
    }

    &[disabled] {
      color: var(--color-natural-60);
      border-color: var(--color-natural-20);
    }
  }
}

.mat-form-field.select-input {
  .mat-select:focus .mat-select-trigger {
    border-color: var(--color-primary-100);
  }

  .mat-select-trigger {
    border-color: var(--color-natural-40);

    &:hover {
      border-color: var(--color-primary-100);
    }
  }
}
