@use 'direction';

// default menu styles
[cdkMenu] {
  margin-top: var(--spacing-base);
  padding: var(--spacing-2x);
  background-color: var(--color-white);
  border: 1px solid var(--color-natural-40);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  [cdkMenuItem] {
    display: block;
    width: 100%;
    padding: var(--spacing-base);
    font-size: 16px;
    font-weight: 400;
    color: var(--color-natural-180);
    background-color: var(--color-white);
    border-radius: 4px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: var(--spacing-2x);
    }

    &:hover {
      text-decoration: none;
    }

    &:hover:not(:disabled) {
      background-color: var(--color-natural-10);
    }

    &:hover:disabled {
      cursor: not-allowed;
    }

    &.selected,
    &.selected:hover {
      background-color: var(--color-secondary-10);
    }

    // colors
    &.natural {
      color: var(--color-natural-100);
    }

    &.primary {
      color: var(--color-primary-100);
    }

    &.secondary {
      color: var(--color-secondary-100);
    }

    &.success {
      color: var(--color-success-100);
    }

    &.warning {
      color: var(--color-warning-100);
    }

    &.danger {
      color: var(--color-danger-100);
    }
  }
}

// DIRECTION
@include direction.biDirectionalViewEncapsulationNone() using($start, $end) {
  [cdkMenu] {
    [cdkMenuItem] {
      text-align: #{$start};
    }
  }
}

.alternative-menu-trigger.cdk-menu-trigger {
  &[aria-expanded='true'] {
    &[accredible-button] {
      background-color: var(--color-primary-120);
    }

    &[accredible-button-outline] {
      background-color: var(--color-primary-10);
      border-color: var(--color-primary-100);
    }
  }
}

[cdkMenu].alternative-menu {
  @extend [cdkMenu];
  display: flex;
  flex-direction: column;
  padding: 0;

  [cdkMenuItem] {
    margin-bottom: 0;
    padding: var(--spacing-base);
    font-size: 18px;
    font-weight: 600;
    border: none;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-natural-40);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover {
      background-color: initial;
    }

    > * {
      display: block;
      width: 100%;
      padding: var(--spacing-2x) var(--spacing-3x);
      color: var(--color-primary-100);
      border-radius: 4px;

      &:hover {
        color: var(--color-primary-120);
        background-color: var(--color-primary-5);
      }
    }
  }
}
