body {
  a {
    color: var(--acc-link-color);
  }
}

// #app-root selector allows us to override styles specified in framework.scss without using "!important"
#app-root {
  .main-header {
    justify-content: space-between;
    background-color: var(--theme-main-header-color);
  }

  main.dashboard-container {
    // We don't support mobile/tablet on dashboard
    min-width: 1260px;
    padding-bottom: 0;
    background-color: var(--color-white);

    // TODO V1(Matt): We can remove these styles
    &.embed-with-sidebar {
      min-width: 970px;
    }
  }
}

.db-content {
  width: 100%;
  max-width: calc(1380px + var(--spacing-12x));
  margin: 0 auto;
  padding: 0 var(--spacing-6x) var(--spacing-12x);
}

//### DIALOG ###
.no-padding-dialog {
  .mat-dialog-container {
    padding: 0;
    border-radius: 10px;
    overflow: hidden;

    .mat-dialog-actions {
      margin-bottom: 0 !important;
    }
  }
}

// TODO: If there is time and if this change is eventually required in
// other places, add a customisation option to the checkbox input
.marketing-roi-container {
  .graph-selector {
    accredible-checkbox-input.alternate-color {
      label input:checked + .input-box {
        background-color: var(--color-primary-60);
        border-color: var(--color-primary-60);
      }

      label:hover input:not(:disabled) + .input-box {
        border-color: var(--color-primary-60);
      }
    }
  }
}

.recipient-activity-graph-container {
  .graph-selector {
    accredible-checkbox-input.issued {
      label input:checked + .input-box {
        background-color: var(--color-primary-80);
        border-color: var(--color-primary-80);
      }

      label:hover input:not(:disabled) + .input-box {
        border-color: var(--color-primary-80);
      }
    }

    accredible-checkbox-input.views {
      label input:checked + .input-box {
        background-color: var(--color-warning-80);
        border-color: var(--color-warning-80);
      }

      label:hover input:not(:disabled) + .input-box {
        border-color: var(--color-warning-80);
      }
    }

    accredible-checkbox-input.shares {
      label input:checked + .input-box {
        background-color: var(--color-secondary-80);
        border-color: var(--color-secondary-80);
      }

      label:hover input:not(:disabled) + .input-box {
        border-color: var(--color-secondary-80);
      }
    }
  }
}

// Funnels
.svg-funnel-js__labels {
  display: none;
}

.recipient-activity-container,
.email-activity-graph-container {
  .ngx-charts .grid-panel.odd rect {
    fill: none;
  }
}

.custom-ngx-charts-tooltip {
  ngx-tooltip-content.ngx-charts-tooltip-content.type-tooltip {
    background: var(--color-white);

    > div {
      background: var(--color-white);
      border: none;
    }

    .tooltip-content {
      border: none;
    }

    .tooltip-caret {
      display: none;
    }
  }
}

// Integrations
.integration-details-container {
  .integration-details-form-wrapper .text-input-group accredible-text-input input {
    background-color: var(--color-white);
  }
}

.auto-issuance {
  .duration accredible-text-input input {
    width: 65px;
  }
}
