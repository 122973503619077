accredible-chip.new-chip-design {
  height: 25px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  color: #63647A;
  background: #EFF0F2;
  border-radius: 4px;

  > div.mat-tooltip-trigger {
    padding: 0;
  }

  span {
    letter-spacing: normal;
  }

  .btn-remove {
    mat-icon.mat-icon.material-icons {
      font-size: 12px;
    }
  }
}
