@use '../new-components/menu/menu-component';

// ---------- Loading icon class and animation ----------
accredible-icon.animation-spin {
  animation-name: animationSpin;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes animationSpin {
  to {
    transform: rotate(360deg);
  }
}

// ------------------------------------------------------
