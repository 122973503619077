/* You can add global styles to this file, and also use other style files */

// Project styles
//@use 'app';

// All scss files below are added in angular.json, they live in libs/styles
// {projectName}.architect.build.options.stylePreprocessorOptions.includePaths

// Accredible styles
@use 'colors';
@use 'variables';
@use 'accessibility';
@use 'framework';
@use 'ghosts';
@use 'mixins';
@use 'reset';
@use 'responsive';
@use 'new-theme';
@use 'new-font';
@use 'new-components';

// TODO(Fred): 'app' scss file can be imported in the beginning again once we remove framework.scss from dashboard
@use 'app';

// Material styles overrides
@use 'material/mat-button-overrides';
@use 'material/mat-dialog-overrides';
@use 'material/mat-elements-overrides';
@use 'material/mat-form-overrides';
@use 'material/mat-menu-overrides';
@use 'material/mat-select-overrides';

// Chips overrides
@use 'chips-overrides';

// Material form components overrides in order to use dashboard css variables
@use 'mat-form-dashboard-overrides';

// Ngx-charts styles overrides
@use 'ngx-charts/ngx-charts-overrides';

// Accredible Font
@use 'acc-icon-font/style';

// Accredible New Icon Font
@use 'acc-new-icon-font/style' as style-new-icon-font;

// Rich Text Editor styles
@use 'rich-text';

svg {
  z-index: 999;
}
